/* ==========================================================================
   Dirt skin
   ========================================================================== */

/* Colors */
$background-color: #f3f3f3 !default;
$text-color: #343434 !default;
$muted-text-color: #8e8b82 !default;
$primary-color: #343434 !default;
$border-color: #e9dcbe !default;
$footer-background-color: #e9dcbe !default;
$link-color: #343434 !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: $text-color !default;
$navicon-link-color-hover: mix(#fff, $text-color, 80%) !default;

/* dirt syntax highlighting (base16) */
$base00: #231e18 !default;
$base01: #302b25 !default;
$base02: #48413a !default;
$base03: #9d8b70 !default;
$base04: #b4a490 !default;
$base05: #cabcb1 !default;
$base06: #d7c8bc !default;
$base07: #e4d4c8 !default;
$base08: #d35c5c !default;
$base09: #ca7f32 !default;
$base0a: #e0ac16 !default;
$base0b: #b7ba53 !default;
$base0c: #6eb958 !default;
$base0d: #88a4d3 !default;
$base0e: #bb90e2 !default;
$base0f: #b49368 !default;
